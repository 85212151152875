@use '../base/sizing' as *;
@use '../base/mixins';
@use '../base/colors';
@use '../base/responsive';

// Font & Text
body{
  h1, h2, h3, h4, h5, h6 {
    color: var(--heading-color);
    font-weight: 600;
    margin: 0;
  }
  h1{ font-size: 3rem; @include mixins.emojiSize(3rem) }
  h2{ font-size: 2rem; @include mixins.emojiSize(2rem) }
  h3{ font-size: 1.5rem; @include mixins.emojiSize(1.5rem) }
  h4{ font-size: 1.25rem; font-weight: 500; @include mixins.emojiSize(1.25rem) }
  h5{ font-size: 1rem; font-weight: 500; @include mixins.emojiSize(1rem) }
  p{ @include mixins.emojiSize(1rem) }
  
  @include responsive.mobile {
    h1{ font-size: $xxl; }
    h2{ font-size: $xl; }
    h3{ font-size: $ml; }
    h4{ font-size: 18px; font-weight: 500; }
    h5{ font-size: $m; font-weight: 500; }
  }
  
  p, span, input, li, label, th, dd, dt, td, pre{
    color: var(--text-color);
    margin: 0;
  }

  p, input, li{
    line-height: 21px;
  }
  
  a{
    text-decoration: inherit;
    color: var(--text-color);
  }

  span[data-type="emoji"] {
    display: inline-block;
  }
}


.weak-text{
  color: var(--text-color-weak);
}

.text-inversed{
  color: var(--text-color-inversed);
}

.text-center{
  text-align: center;
}

.container-title, .page-title{
  margin-top: 0;
}

.container-title{
  margin-bottom: $ms;
}

.text-color-normal{ color: var(--text-color); }
.text-primary{ color: var(--primary); }
.text-secondary{ color: var(--secondary); }
.text-tertiary{ color: var(--tertiary); }
.text-success{ color: var(--success); }
.text-inactive{ color: var(--inactive); }
.text-white-on-dark{ color: var(--white-on-dark); }
.text-dark{ color: colors.$text-color-dark }


article{
  p, li{
    white-space: pre-line;
    line-height: 1.75rem;
    color: #424242;
    
    @include mixins.onDarkMode{
      color: #D8D4CF;
    }
  }
  
  h1{
    margin-top: .5rem;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    letter-spacing: -.025em;
  }

  h2{
    font-weight: 600;
    letter-spacing: -.025em;
    margin-top: 2.5rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding-bottom: .25rem;
  }

  h3 {
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: -.025em;
  }

  h4 {
    margin-top: 1.75rem;
    line-height: 1.75rem;
    font-weight: 600;
    letter-spacing: -.025em;
    font-size: 1.25rem;
  }

  h5 {
    margin-top: 1.75rem;
    line-height: 1.75rem;
    font-weight: 600;
    letter-spacing: -.025em;
    font-size: 1.125rem;
  }

  .table-container:not(:first-child),
  blockquote:not(:first-child),
  p:not(:first-child) {
    margin-top: 1rem;
  }
  
  ol, ul{
    margin-top: 0.5rem;
  }

  li ol{
    margin-top: 0.2rem;
  }
}