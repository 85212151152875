@use '../base/sizing' as *;
@use '../base/colors';

.myCustomHeight{
  height: clamp(300px, 100vh, 640px);
}

.rbc-time-view, .rbc-month-view, .rbc-agenda-view{
  border-radius: $b-rad;
}

.rbc-time-view .rbc-event{
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
}
.rbc-time-view .rbc-row{
  min-height: 35px;
}

.half-hour .rbc-event-label{
  display: none;
}
.one-hour{
  .rbc-event-content{
    display: flex;
  }
}

.rbc-time-view .rbc-event-content span{
  color: white;
}

.rbc-time-gutter, .rbc-time-header-gutter{
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important;
}

.rbc-date-cell{
  padding-left: 5px;
  text-align: left;
}

.rbc-off-range-bg{
  background: var(--background-color-3);
}

.rbc-today{
  background-color: var(--background-color-2);
}
.rbc-now .rbc-button-link{
  background-color: var(--primary);
  border-radius: 100%;
  height: 24px;
  width: 24px;
  // margin: 4px; // will move entire row, find better approach
  color: var(--text-light);
}

.rbc-button-link{
  color: var(--text-color);
}

// toolbar
.rbc-toolbar{
  
  button{

    &:hover{
      background-color: var(--background-color-3);
    }

    &:not(.rbc-active){
      color: var(--text-color);
    }
  }
}

.rbc-day-bg+.rbc-day-bg,
.rbc-month-row+.rbc-month-row,
.rbc-month-view,
.rbc-agenda-view,
.rbc-time-view,
.rbc-header,
.rbc-time-content>*+*>*,
.rbc-agenda-view table.rbc-agenda-table,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-color: var(--subtle) !important;
}
.rbc-time-header.rbc-overflowing{
  border-right: 2px solid transparent;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td{
  border-left: 1px solid var(--subtle);
}

.rbc-day-slot .rbc-time-slot {
  border: none;
}

.rbc-day-slot .rbc-events-container{
  margin-right: 3px;
  margin-left: 3px;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event, .rbc-allday-cell .rbc-event-allday.rbc-event{
  background-color: rgba(var(--primary-rgb), 0.8);
  border: $b-width solid var(--primary);
  
  &:hover{
    background-color: rgba(var(--primary-rgb), 0.9);
  }
}

.rbc-row-content .rbc-event-allday{
  background-color: rgba(var(--primary-rgb), 0.15);

  &:hover{
    background-color: rgba(var(--primary-rgb), 0.25);
  }
}

.rbc-event{
  background-color: transparent;
  // outline: 1.5px solid var(--text-color);
  
  &.rbc-selected{
    background-color: transparent;
  }

  &:focus{
    outline: none;
  }
}
.rbc-show-more{
  padding-left: 5px;

  &:hover{
    color: var(--primary);
  }
}

.is-selecting-signups.is-selectable-signup .rbc-event{
  background-color: var(--inactive);
}
.is-selecting-signups.is-selectable-signup.is-selected .rbc-event{
  background-color: var(--primary);
}
.rbc-month-view .is-selectable-signup.is-selected span{
  color: white;
}

.rbc-day-slot .rbc-timeslot-group:nth-child(24){
  border-bottom: none;
}

.rbc-slot-selection{
  background-color: rgba(var(--primary-rgb), 0.3);
  border-radius: $b-rad;
  display: flex;
  justify-content: center;
  align-items: center;
  
  span{
    font-size: 12px;
    font-weight: 500;
  }
}


.rbc-current-time-indicator{
  background-color: var(--primary);
  height: 2px;
}

// apple-esque calendar styling
.rbc-time-header-content{
  border: none;
}

.rbc-header + .rbc-header{
  border-left: none;
}
.rbc-header{
  padding: $s $xs;
}
.rbc-allday-cell{
  border-left: 1px solid var(--subtle);
}

.rbc-timeslot-group{
  min-height: 55px;
}
.rbc-time-gutter .rbc-timeslot-group{
  border: none;
  transform: translate(-0px, -10px);
  span{
    color: var(--text-color-weak);
  }
}


.rbc-time-content .rbc-day-slot:nth-child(2) .rbc-timeslot-group {
  border-left: none;
}

.rbc-time-content{
  border-top: $b-width solid var(--subtle);
}