@use '../base/sizing' as *;
@use '../base/colors';
@use '../base/mixins';

.member-name, .member-name[type='button']{
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: $b-rad;
  padding: 6px 7px;
  border: $b-width solid transparent;
  background-color: var(--background-color-1);

  @include mixins.classContainer();

  &:not(.ghost-member){
    &:hover, &.memberIsUser{
      border: $b-width solid var(--inherit-color);
    }
    &.show-border {
      border: $b-width solid var(--inherit-color);
    }
    
    &.valid-drag {
      border: $b-width solid colors.$success;
    }
  }

  &:hover, &.memberIsUser{
    border: $b-width dashed var(--inherit-color);
  }
  &.show-border {
    border: $b-width dashed var(--inherit-color);
  }
  
  &.valid-drag {
    border: $b-width dashed colors.$success;
  }

  span{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .standby-true{
    height: 6px;
    width: 6px;
    background: colors.$info;
    border-radius: 50%;
  }

  .member-confirm{
    height: 6px;
    width: 6px;
    background: colors.$warning;
    border-radius: 50%;
    
    &.confirmed-true{
      background: colors.$success;
    }
  }
  
  &.ghost-member{
    border: $b-width dashed colors.$inactive;
  }
}