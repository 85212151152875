@use '../base/sizing' as *;
@use '../base/colors';

$datepicker__background-color: colors.$white-highlight !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: colors.$success !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: colors.$primary !default;
$datepicker__text-color: var(--text-color) !default;
$datepicker__header-color: var(--text-color) !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial,
  sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

@import '~react-datepicker/src/stylesheets/mixins.scss';
@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker-popper{
  z-index: 10;
}

.dates.range{
  .react-datepicker-wrapper{
    &:not(:last-of-type){
      margin-right: $s;
    }
  }
}

.react-datepicker-wrapper{
  flex: 1;
}
.react-datepicker__day--range-start{
  
  // background-color: #EAEEF2;
  // color: var(--text-color);
  border-radius: 0.3rem;
  background-color: #26547C;
  color: #fff;
}
.react-datepicker__time-container--with-today-button{
  right: -95px;
}
.react-datepicker__time-container{
  width: auto !important;
}
.react-datepicker__time-list{
  padding: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle{
  border-top-color: colors.$white-highlight;
}
.react-datepicker__day--disabled, .react-datepicker__time-list-item--disabled{
  color: var(--inactive) !important;
}