@use '../base/colors';
@use './effects';

@mixin base() {
  --background-color:   #{colors.$light-1};
  --background-color-1: #{colors.$light-2};
  --background-color-2: #{colors.$light-3};
  --background-color-3: #{colors.$light-4};

  --background-color-inversed:   #{colors.$dark-1};
  --background-color-1-inversed: #{colors.$dark-2};
  --background-color-2-inversed: #{colors.$dark-3};
  --background-color-3-inversed: #{colors.$dark-4};

  --pop-over-bg: #{colors.$light-1};
  --pop-over-shadow: #{effects.$pop-over-shadow-light-mode};

  --primary: #{colors.$primary};
  --primary-rgb: #{colors.$primary-rgb};
  --primary-hover: #{colors.$primary-hover};
  --primary-text: #{colors.$primary};
  --primary-text-inversed: #{colors.$text-color-light};
  --white-on-dark: #{colors.$primary};
  --white-on-dark-rgb: #{colors.$primary-rgb};
  --white-on-dark-inversed: white;

  --heading-color: #{colors.$heading-color-dark};
	--text-color: #{colors.$text-color-dark};
	--text-color-weak: #7E838D;
  
  --heading-color-inversed: #{colors.$heading-color-light};
  --text-color-inversed: #{colors.$text-color-light};
  --text-color-weak-inversed: #7E838D;

  --secondary: #{colors.$secondary};
  --secondary-rgb: #{colors.$secondary-rgb};
  --secondary-hover: #{colors.$secondary-hover};

  --tertiary: #{colors.$tertiary};
  --tertiary-rgb: #{colors.$tertiary-rgb};
  --tertiary-hover: #{colors.$tertiary-hover};

  --success: #{colors.$success};
  --success-rgb: #{colors.$success-rgb};
  --success-hover: #{colors.$success-hover};

  --info: #{colors.$info};
  --info-rgb: #{colors.$info-rgb};
  --info-hover: #{colors.$info-hover};

  --warning: #{colors.$warning};
  --warning-rgb: #{colors.$warning-rgb};
  --warning-hover: #{colors.$warning-hover};

  --error: #{colors.$error};
  --error-rgb: #{colors.$error-rgb};
  --error-hover: #{colors.$error-hover};

  --inactive: #{colors.$inactive};
  --inactive-rgb: #{colors.$inactive-rgb};
  --inactive-hover: #{colors.$inactive-hover};

  --text-light: #{colors.$text-color-light};
  --heading-light: #{colors.$heading-color-light};
  --text-dark: #{colors.$text-color-dark};
  --heading-dark: #{colors.$heading-color-dark};

  --app-height: 100%;
  --yellow: #{colors.$rogue};
  --scroll-margin: 60px;
  --subtle: #{colors.$subtle};
  --subtle-hover: #{colors.$subtle-hover};
  --subtle-border: #d0d7de;
  --wow-priest: #{colors.$priest-dark};
}

@mixin darkModeOverrides() {
  --primary: #{colors.$primary-lighter};
  --primary-rgb: #{colors.$primary-lighter-rgb};
  --primary-hover: #{colors.$primary-hover-lighter};

  --background-color:   #{colors.$dark-1};
  --background-color-1: #{colors.$dark-2};
  --background-color-2: #{colors.$dark-3};
  --background-color-3: #{colors.$dark-4};
  
  --background-color-inversed:   #{colors.$light-1};
  --background-color-1-inversed: #{colors.$light-2};
  --background-color-2-inversed: #{colors.$light-3};
  --background-color-3-inversed: #{colors.$light-4};
  
  --heading-color: #{colors.$heading-color-light};
  --text-color: #{colors.$text-color-light};
  --primary-text: #{colors.$text-color-light};
  --primary-text-inversed: #{colors.$primary};
  --text-color-weak: #7E838D;
  
  --heading-color-inversed: #{colors.$heading-color-dark};
	--text-color-inversed: #{colors.$text-color-dark};
	--text-color-weak-inversed: #7E838D;

  --pop-over-bg: #{colors.$dark-3};
  --pop-over-shadow: #{effects.$pop-over-shadow-dark-mode};

  --inactive: #{colors.$inactive-on-dark};
  --inactive-hover: #{colors.$inactive-on-dark-hover};
  --white-on-dark: white;
  --white-on-dark-rgb: #{colors.$white-rgb};
  --white-on-dark-inversed: #{colors.$primary};
  --subtle: #{colors.$subtle-dark};
  --subtle-hover: #{colors.$subtle-dark-hover};
  --subtle-border: #30363d;
  --wow-priest: #{colors.$priest};
}

@mixin lightModeOverrides() {
  --background-color:   #{colors.$light-1};
	--background-color-1: #{colors.$light-2};
	--background-color-2: #{colors.$light-3};
	--background-color-3: #{colors.$light-4};
  
	--background-color-inversed:   #{colors.$dark-1};
	--background-color-1-inversed: #{colors.$dark-2};
	--background-color-2-inversed: #{colors.$dark-3};
	--background-color-3-inversed: #{colors.$dark-4};
  
	--heading-color: #{colors.$heading-color-dark};
	--text-color: #{colors.$text-color-dark};
	--text-color-weak: #7E838D;
  
  --heading-color-inversed: #{colors.$heading-color-light};
  --text-color-inversed: #{colors.$text-color-light};
  --text-color-weak-inversed: #7E838D;
  
  --pop-over-bg: #{colors.$light-1};
  --pop-over-shadow: #{effects.$pop-over-shadow-light-mode};

  --white-on-dark: #{colors.$primary};
  --white-on-dark-rgb: #{colors.$primary-rgb};
  --white-on-dark-inversed: white;

  --primary: #{colors.$primary};
  --primary-rgb: #{colors.$primary-rgb};
  --primary-hover: #{colors.$primary-hover};
  --primary-text: #{colors.$primary};
  --primary-text-inversed: #{colors.$text-color-light};

  --inactive: #{colors.$inactive};
  --inactive-rgb: #{colors.$inactive-rgb};
  --inactive-hover: #{colors.$inactive-hover};
  --wow-priest: #{colors.$priest-dark};
}

:root{
  @include base;

  &[data-theme="light"]{
    @include lightModeOverrides;
  }

  &[data-theme="dark"] {
    @include darkModeOverrides;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @include darkModeOverrides;
  }
}