@use '../base/sizing' as *;

.role-tag{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color-3-inversed);
  padding: 0;
  height: 0px;
  border-radius: $b-rad $b-rad 0 0;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  
  > *{
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  span{
    color: var(--text-color-inversed);
    font-size: 12px;
  }
}