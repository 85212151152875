@use './partials/text';
@use './partials/theme';
@use './partials/global';
@use './partials/flex';
@use './partials/effects';
@use './partials/button';

// libraries
@use 'react-big-calendar/lib/sass/styles';

@use './partials/tipTapEditor';
@use './partials/reactBigCalendar';
@use './partials/reactSelect';

@use './components/groupMemberName';
@use './components/drag';
@use './components/roleTag';
@use './components/dateSelect';
@use './components/form';

@tailwind base;
@tailwind components;
@tailwind utilities;

