// @import '../../../global/var.scss';
@use '../base/colors';
@use '../base/sizing' as *;

// buttons
.btn{
  padding: $ms $l;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background: none;
  outline: none !important;

  &.clickable{
    cursor: pointer;
  }

  .text-container, .svg-icon{
    position: relative;
  }

  --ec-btn-bg-color: var(--primary, #{colors.$primary});
  --ec-btn-bg-color-hover: var(--primary-hover, #{colors.$primary-hover});
  --ec-btn-text-color: white;
  --ec-btn-text-color-hover: white;
  $trans-not-hover: 0.085;
  $trans-hovered: 0.12;

  // should not be used normal style
  &.white-on-dark{
    --ec-btn-bg-color: var(--primary-text, white);
    --ec-btn-bg-color-hover: var(--primary-text, white);
    --ec-btn-text-color: var(--white-on-dark-inversed);
    --ec-btn-text-color-hover: var(--white-on-dark-inversed);
  }
  &.primary-inversed{
    --ec-btn-bg-color: var(--primary-text-inversed, #{colors.$primary});
    --ec-btn-bg-color-hover: var(--primary-text-inversed, #{colors.$primary});
    --ec-btn-text-color: white;
    --ec-btn-text-color-hover: white;
  }
  // should not be used normal style
  &.white{
    --ec-btn-bg-color: white;
    --ec-btn-bg-color-hover: white;
    --ec-btn-text-color: var(--text-light, white);
    --ec-btn-text-color-hover: var(--text-light, white);
  }

  &.secondary{
    --ec-btn-bg-color: var(--secondary, #{colors.$secondary});
    --ec-btn-bg-color-hover: var(--secondary-hover, #{colors.$secondary-hover});
    --ec-btn-text-color: white;
    --ec-btn-text-color-hover: white;
  }

  &.tertiary{
    --ec-btn-bg-color: var(--tertiary, #{colors.$tertiary});
    --ec-btn-bg-color-hover: var(--tertiary-hover, #{colors.$tertiary-hover});
    --ec-btn-text-color: white;
    --ec-btn-text-color-hover: white;
  }

  &.success{
    --ec-btn-bg-color: var(--success, #{colors.$success});
    --ec-btn-bg-color-hover: var(--success-hover, #{colors.$success-hover});
    --ec-btn-text-color: white;
    --ec-btn-text-color-hover: white;
  }

  &.inactive{
    --ec-btn-bg-color: var(--inactive, #{colors.$inactive});
    --ec-btn-bg-color-hover: var(--inactive-hover, #{darken($color: colors.$inactive, $amount: 5%)});
    --ec-btn-text-color: white;
    --ec-btn-text-color-hover: white;
  }
  
  &.disabled{
    --ec-btn-bg-color: var(--inactive, #{colors.$inactive});
    --ec-btn-bg-color-hover: var(--inactive, #{colors.$inactive});
    --ec-btn-text-color: black;
    --ec-btn-text-color-hover: black;
    cursor: default;
  }

  &.darkText{
    --ec-btn-text-color: var(--text-dark);
    --ec-btn-text-color-hover: var(--text-dark);
  }

  // STYLES
  &.submit-btn{
    background-color: var(--ec-btn-bg-color);
    background: var(--ec-btn-bg-color);
    border-radius: 4px;
    cursor: pointer;

    &:hover{
      background-color: var(--ec-btn-bg-color-hover);
      background: var(--ec-btn-bg-color-hover);
    }
  }
  
  &::before {
    background-color: var(--ec-btn-bg-color);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    border-radius: inherit;
  }

  color: var(--ec-btn-text-color);
  
  &:hover {
    color: var(--ec-btn-text-color-hover, var(--ec-btn-text-color));

    &::before {
      background-color: var(--ec-btn-bg-color-hover);
    }
  }

  &.soft{
    color: var(--ec-btn-bg-color);

    &::before {
      background-color: var(--ec-btn-bg-color);
      opacity: $trans-not-hover;
    }
    
    &:hover{
      &::before {
        background-color: var(--ec-btn-bg-color-hover);
        opacity: $trans-hovered;
      }
    }
  }
  
  &.transparent{
    color: var(--ec-btn-bg-color);

    &::before{
      background-color: var(--ec-btn-bg-color);
      opacity: 0;
    }

    &:hover{
      &::before{
        background-color: var(--ec-btn-bg-color-hover);
        opacity: $trans-hovered;
      }
    }
  }

  &.outline{
    color: var(--ec-btn-bg-color);

    &::before{
      box-sizing: border-box;
      background: none;
      border: 1.5px solid var(--ec-btn-bg-color);
      opacity: 0.7;
    }
    
    &:hover{
      &::before{
        opacity: 1;
        border: 1.5px solid var(--ec-btn-bg-color-hover);
      }
    }
  }

  // SIZES
  &.micro, &.micro-sq, &.mini, &.mini-sq{
    font-size: 13px;
    font-weight: 500;
  }
  &.micro{
    padding: $xs $s;
  }
  &.mini{
    padding: $s $m;
  }

  &.small, &.small-sq{
    font-size: 13px;
    font-weight: 400;
  }
  &.small{
    padding: $ms $l;
  }

  &.big{
    padding: $m $xl;
    font-size: 18px;
    font-weight: 400;
  }

  &.micro-sq{
    padding: $xs $xs;
  }
  &.mini-sq{
    padding: $s $s;
  }
  &.small-sq{
    padding: $ms $ms;
  }

  &.fullwidth{
    width: 100%;
  }
  &.fullHeight{
    height: 100%;
  }

  .text-container{
    white-space: nowrap;
    line-height: normal;
    
    &.icon-left{
      margin-left: $s;
    }
    &.icon-right{
      margin-right: $s;
    }
  }

  &.align-text-left {
    justify-content: flex-start;
  }
  &.align-text-right {
    justify-content: flex-end;
  }
}

.plus-btn, .minus-btn{
  display: flex;
  justify-content: center;
  align-items: center;

  height: 48px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  background: colors.$white-highlight;
  // margin-top: $ms;
  border-radius: $s;
  cursor: pointer;

  &:active{
    color: colors.$primary-hover;
  }
}

.plus-and-minus{
  display: flex;
  flex-flow: row nowrap;

  .plus-btn, .minus-btn{
    width: calc(50% - #{$s});
    margin-top: 0;
  }

  .plus-btn{
    margin-right: $s;
  }

  .minus-btn{
    margin-left: $s;
  }
}

// stars & rating
.star-container{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 25px;

  .star{
    margin-right: $s;
    width: 20px;

    &.clickable{
      cursor: pointer;
    }
  }
}