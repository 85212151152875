@use '../../../global/styles/base/sizing' as *;

.popoverTwo{
  background: var(--pop-over-bg);
  box-shadow: var(--pop-over-shadow);
  z-index: 20;
  border-radius: $b-rad;
  padding: $s;
}

[data-side="top"]{
  .tooltipArrow{
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

[data-side="bottom"]{
  .tooltipArrow{
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }
}

.popOver{
  position: absolute;
  min-width: max-content;
  background: var(--pop-over-bg);
  box-shadow: var(--pop-over-shadow);
  z-index: 10;
  border-radius: $b-rad;
  padding: $s;

  &.top{
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    .tooltipArrow{
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.bottom{
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: calc(100% + #{$m});

    .tooltipArrow{
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }
  }

  &.bottomLeft{
    left: 0;
    top: calc(100% + #{$m});

    .tooltipArrow{
      bottom: 100%;
      left: $xs;
      transform: rotate(180deg);
    }
  }

  &.bottomRight{
    right: 0;
    top: calc(100% + #{$m});

    .tooltipArrow{
      bottom: 100%;
      right: -$xs;
      transform: translateX(-50%) rotate(180deg);
    }
  }

  &.center{
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    .tooltipArrow{
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.rightTop{
    left: calc(100% + #{$m});
    top: 0;

    .tooltipArrow{
      right: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(90deg);
      transform: translateY(-50%) rotate(90deg);
    }
  }
}

.tooltipArrow {
  width: 20px;
  height: 20px;
  position: absolute;
  overflow: hidden;
  z-index: 11;
  
  &::after {
    border-radius: $b-rad;
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--pop-over-bg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: -5px;
    left: 50%;
  }
}
