@use '../../../../global/styles/base/sizing' as *;

.wrapper{
  display: inline-block;
}

.container{
  padding: 0 3px;
  border-radius: $b-rad;
  cursor: pointer;
  background-color: var(--background-color-1);
  border: 1px solid var(--background-color-2)
}