@use '../base/responsive';

.left-right-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  .left, .right{
    height: 100%;
  }

  &.wrap{
    flex-flow: row wrap;
  }

  &.col-on-mobile{
    @include responsive.mobile {
      flex-flow: column nowrap;
      
      &.reverse{
        flex-flow: column-reverse nowrap;
      }
    }
  }
}

.center-center{
  display: flex;
  align-items: center;
  justify-content: center
}

.col{
  display: flex;
  flex-flow: column nowrap;
}

.row{
  display: flex;
  flex-flow: row nowrap;
  &.wrap{
    flex-flow: row wrap;
  }
}
.flex-row{
  display: flex;
  flex-flow: row nowrap;
  &.wrap{
    flex-flow: row wrap;
  }
}
.flex-col{
  display: flex;
  flex-flow: column nowrap;
  &.wrap{
    flex-flow: column wrap;
  }
}
.flex1{ flex: 1; }
.flex15{ flex: 1.5; }
.flex2{ flex: 2; }
.flex3{ flex: 3; }

.align-self-end{ align-self: flex-end; }
.align-self-center{ align-self: center; }
.align-self-start{ align-self: flex-start; }

.align-end{ align-items: flex-end; }
.align-center{ align-items: center; }
.align-start{ align-items: flex-start; }

.justify-start{ justify-content: flex-start; }
.justify-center{ justify-content: center; }
.justify-end{ justify-content: flex-end; }
.justify-space-between{ justify-content: space-between; }

.no-flex-shrink{ flex-shrink: 0; }


// GRID
.doubleColSpan {
  grid-column: span 2;
}
.tripleColSpan {
  grid-column: span 2;
}
.quadroupleColSpan {
  grid-column: span 2;
}