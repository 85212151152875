@use '../base/sizing' as *;
@use '../base/colors' as *;
@use '../base/mixins';

.row{
  // maybe put this in .modal

  &:not(:last-of-type){
    margin-bottom: $l;
  }

  &.double{
    .input-container{
      max-width: 50%;
    }
  }
  &.double, &.triple{
    .cell.checkbox{
      padding-bottom: 7px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &:last-child{
    margin-bottom: 0;
  }

  .input-container{
    display: flex;
    flex-flow: column nowrap;
    // justify-content: space-between;
    width: 100%;

    &:first-of-type{
      margin-right: $s;
    }

    &:nth-child(2){
      margin-left: $s;
      margin-right: $s;
    }

    &:last-child{
      margin-right: 0;
    }

    .label-container{
      display: flex;
      flex-flow: row wrap;
      // max-width: calc(25% - #{$m});
      align-items: center;
      margin-bottom: $ms;
      label{
        color: $medium-light;
      }
    }
    
    textarea{
      // height: max-content;
      min-height: 100px;
    }
  
    input, textarea, .cell.dropdown, .auto-suggest, .cell.file{

      &:not([type='checkbox']){

        flex-grow: 1;
        // max-width: 75%;
        height: min-content;
      }
      .react-autosuggest__input{
        height: auto;
        margin-bottom: $s;
      }

      &.auto-suggest{
        input{
          max-width: 100%;
        }
      }
    }

    .checkbox{
      input{
        width: min-content;
      }
    }
    label{
      color: var(--text-color);
      font-size: 14px;
    }
  }


  &.single{
    .cell.multicheckbox .cell.checkbox{
      padding-left: 0%;
    }
    .info-cell{

      .info-title{
        font-weight: 500;
        margin-bottom: $s;
      }
      .intro{
        padding-left: 0;
        margin-bottom: 0;
      }
    }
  }

  &:last-child{
    margin-bottom: 0;
  }
}
.row-title{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: $l;
  margin-top: 0;
  padding-left: $l;
  // padding-bottom: $m;
  // border-bottom: 4px solid $white-highlight;
  
  &:not(:first-of-type){
    margin-top: $l;
  }
}

// TEXT FIELD
.required::-webkit-input-placeholder, .required:-ms-input-placeholder { /* WebKit, Blink, Edge */
  color:    #FA5660;
}

.required:-moz-placeholder, .required::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #FA5660;
 opacity:  1;
}

.required::placeholder { /* Most modern browsers support this now. */
 color:    #FA5660;
}

.field-label-container{
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}


.cell, .react-autosuggest__input{
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  
  margin: 0;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  
  &.text-field, &.react-autosuggest__input, .react-datepicker__input-container input{
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: $ms $m;
    font-size: 14px;

    -webkit-transition: border .3s;
    transition: border .3s;

    &.error{
      border-color: #FA5660 !important;
    }

    &:hover{
      border-color: $primary;
    }

    &:focus{
      outline: none;
      border-color: $primary;
    }
  }
  &.multicheckbox{
    display: flex;
    flex-flow: row wrap;
    // max-width: 75%;

    .image-checkbox{
      p{
        margin: 0;
      }
    }
    .svg-icon{
      max-width: 32px;
    }
  }
  &.checkbox{
    &:not(.image){

      max-width: max-content;
  
      label{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
  
        max-width: max-content;
  
        cursor: pointer;
        padding: $s $ms;
        border-radius: $s;
        transition: all 0.2s ease;
        
        &:not(:last-of-type){
          margin-bottom: $s;
          margin-right: $s;
        }
        
        &:hover{
            background-color: $white-highlight
        }
        span{
          float: left;
          vertical-align: middle;
          transform: translate3d(0, 0, 0);
          box-sizing: border-box;
        }
        .left{
          display: flex;
          justify-content: center;
          align-items: center;
          height: auto;
          span{
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: $xs;
            transform: scale(1);
            border: 2px solid #c8ccd4;
            transition: all 0.2s ease;
            margin-right: $s;
            svg{
              position: absolute;
              top: 2px;
              left: 1px;
              fill: none;
              stroke: #fff;
              stroke-width: 2;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-dasharray: 16px;
              stroke-dashoffset: 16px;
              transition: all 0.3s ease;
              transition-delay: 0.1s;
              transform: translate3d(0, 0, 0);
            }
          }
        }
        .right{
          span{
            line-height: 18px;
            width: 100%;
          }
        }
        &:hover span:first-of-type{
          border-color: $primary;
        }
        a{
          color: $info;
        }
      }

      input:checked + label{
        .checkmark{
          &:first-of-type{
            background: $primary;
            border-color: $primary;
            animation: wave .4s ease;
            svg{
              stroke-dashoffset: 0;
            }
          }
        }
      }
    }
    &.image{
      width: calc(50% - (#{$m} / 2));

      &:nth-child(odd){
        margin-right: $m;
      }
      margin-bottom: $m;

      label{
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
  
        max-width: calc(350px - #{$m});
        padding: $ms $m;
  
        background: white;
        border: 2px solid rgba($primary, 0.7);
        border-radius: $s;
        
        img{
          width: $xl;
        }

        .svg-icon{
          margin-bottom: 6px;
        }
  
        &.active{
          background: $primary;
          p{
            color: white;
          }
        }
      }
    }
  }
  &.dropdown{
    padding: 0;
    height: auto;

    select{
      width: 100%;
      margin: 0;
      font-size: $m;
      padding: $ms $m;
    }
    label{
      display: inline-block;
      margin: 0;
      margin-bottom: $s;
    }
  }
  &.radio{
    // max-width: 75%;
    display: flex;
    flex-flow: row wrap;
    
    .radio {  
      &:not(:last-of-type){
        margin-right: $s;
      }
      input {
        position: absolute;
        pointer-events: none;
        visibility: hidden;
      }
      
      input:focus + label {     
        background: #eeeeff;
        
        .checker {
          border-color: $primary;
        }
      }
      
      input:checked + label {    
        .checker {
          box-shadow: inset 0 0 0 6px $primary;
        }
      }
      
      label {
        display: flex;
        align-items: center;
        border-radius: $s;
        padding: 8px 12px;
        cursor: pointer;
        transition: background-color .3s ease;
        
        p{
          margin: 0;
          line-height: 18px;
        }
    
        &:hover {
          // background: #eeeeff;
          background-color: $white-highlight;
          
          .checker {
            box-shadow: inset 0 0 0 2px $primary;
          }
        }
      }
      
      .checker {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 8px;
        box-shadow: inset 0 0 0 2px #ccc;
        transition: box-shadow .3s ease;
      }
    }
  }
  &.file{
    height: auto;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    label {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 75px;
      // max-width: 300px;
      border: 2px solid rgba($primary, 0.7);

      margin: 0;
      border-radius: $s;
      font-size: 1em;
      text-align: center;
      cursor: pointer;

      &:hover, &:active, &.active{
        border: 2px solid rgba($primary, 1);
      }
      &.uploading{
        border: 1px solid rgba($warning, 1);
      }
      &.finished{
        border: 1px solid rgba(green, 1);
      }

      img{
        margin-right: $ms;
        opacity: 0.9;
      }
      p{
        margin: 0;
      }
    }
    
    
    input {
      border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px; 
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

  }
  &.dates{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0;

    .react-datepicker-wrapper{
      width: calc(50% - #{$s});
      
      .react-datepicker__input-container{
        width: 100%;
        
        input{
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
        }
      }
    }
  }
}


.react-autosuggest__container {
  position: relative;
  // margin-bottom: 200px;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 41px;
  width: calc(100% - 2px);
  border: 1px solid #aaa;
  background-color: white;
  font-weight: normal;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  border-radius: $xs;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;

  border-radius: 0;

  &:first-of-type{
    border-radius: 3px 3px 0 0;
  }

  &:last-of-type{
    border-radius: 0 0 3px 3px;
  }

}

.react-autosuggest__suggestion--highlighted {
  background-color: #EAEEF2;

  border-radius: 0;

  &:first-of-type{
    border-radius: 3px 3px 0 0;
  }

  &:last-of-type{
    border-radius: 0 0 3px 3px;
  }
}

@keyframes wave{
  50%{
    transform: scale(0.8);
  }
}


#step-container{
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  padding-left: $l;
  padding-right: $l;
  display: flex;
  justify-content: space-evenly;
  flex-flow: row nowrap;


  .step{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .step-name{
      margin-bottom: $xs;
      color: $inactive;
      font-weight: 500;
    }

    &.active{
      .step-name{
        color: $primary;
      }
  
      .step-circle{
        background: $primary;
        border: none;
  
        .step-number{
          color: var(--text-color-inversed);
        }
      }
    }
  
    &.done{
      .step-name{
        color: $success;
      }
  
      .step-circle{
        background: $success;
        border: none;
  
        .step-number{
          color: var(--text-color-inversed);
        }
      }
    }

    .step-circle{
      height: $xl;
      width: $xl;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-sizing: border-box;
      padding: $s;
      border: 2px solid $inactive;  
      
      .step-number{
        color: $inactive;
        font-weight: 500;
        margin: 0;
      }
    }
  }

  
}