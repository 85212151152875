@use '../base/sizing' as *;
@use '../base/colors';
@use '../base/responsive';
@use '../base/mixins';

:root {
  font-size: 16px;
  scroll-behavior: smooth;
}

body{
  margin: 0;
  padding: 0;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  background: var(--background-color);
  font-size: 0.813rem;
  
  *{
    box-sizing: border-box;
  }

  // anti tailwind
  ul, ol {
    list-style: revert;
    padding-left: 20px;
  }
  button, [role="button"] {
    cursor: inherit;
  }

  // ::-webkit-scrollbar{
  //   display:none;
  // } 
}

.scroll-margin{
  scroll-margin-top: 60px;
}

button{
  margin: 0;
}
::selection{
  background-color: highlight;
}
a.link{
  text-decoration: underline;

  &:hover{
    color: var(--primary) !important;
  }
}

// Background
.bg1{ background-color: var(--background-color) }
.bg2{ background-color: var(--background-color-1) }
.bg3{ background-color: var(--background-color-2) }
.bg4{ background-color: var(--background-color-3) }

.bg1-inversed{ background-color: var(--background-color-inversed) }
.bg2-inversed{ background-color: var(--background-color-1-inversed) }
.bg3-inversed{ background-color: var(--background-color-2-inversed) }
.bg4-inversed{ background-color: var(--background-color-3-inversed) }

.bg-primary{ background-color: var(--primary) !important }
.bg-secondary{ background-color: var(--secondary) !important }
.bg-tertiary{ background-color: var(--tertiary) !important }
.bg-success{ background-color: colors.$success !important }
.bg-inactive{ background-color: var(--inactive) !important }
.bg-primary-subtle{ 
  @include mixins.before{
    background-color: var(--primary) !important;
    opacity: 0.1;
  }
}
.bg-dark{ background-color: colors.$dark-1 !important }
.bg-dark-subtle{ 
  @include mixins.before{
    background-color: colors.$dark-1 !important;
    opacity: 0.8;
  }
}

// round corners
.rounded { border-radius: $b-rad; }
.rounded-circle { border-radius: 100% }
.rounded-max { border-radius: 100px; }
.rounded-top { border-radius: $b-rad $b-rad 0 0; }
.rounded-right { border-radius: 0 $b-rad $b-rad 0 !important; }
.rounded-bottom { border-radius: 0 0 $b-rad $b-rad; }
.rounded-left { border-radius: $b-rad 0 0 $b-rad !important; }
.rounded-removed { border-radius: 0 !important; }

// utility classes
.hidden, #__next-prerender-indicator{
  display: none !important;
}
.hideIfEmpty:empty{
  display: none !important;
}
.hideOnMobile{
  @include responsive.mobile{
    display: none !important;
  }
}
.hideChildrenOnMobile > *{
  @include responsive.mobile{
    &:not(.preventHideChildOnMobile){
      display: none !important;
    }
  }
}
.hideOnTablet{
  @include responsive.tablet{
    display: none !important;
  }
}
.hideOnDesktop{
  @include responsive.desktop{
    display: none !important;
  }
}
.hideBelowDesktop{
  @include responsive.belowWidth(responsive.$desktop-width) {
    * {
      display: none !important;
    }
  }
}

.hover-hide-parent:not(:hover){
  .hover-hide-child{
    display: none !important;
  }
}

.responsive-iframe-container{
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 200px;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.noselect, .noselect * {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.no-click { pointer-events: none; }
.click-enabled { pointer-events: auto; }
.no-click-inside *{ pointer-events: none; }

.clickable{ cursor: pointer; }
.clickable-container *{ cursor: pointer; }
.selectable{
  cursor: pointer;
  box-shadow: 0 0 0 1.5pt colors.$inactive;
      
  &:not(.no-hover):hover{
    box-shadow: 0 0 0 1.5pt colors.$info-hover;
  }
  &.selected{
    box-shadow: 0 0 0 1.5pt colors.$info;
  }
}
.pos-rel{ position: relative; }
.absolute{ position: absolute !important; }
.absolute-fill{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.flex1{ flex: 1; }
.drop-shadow { box-shadow: var(--pop-over-shadow); }

.r90{ transform: rotate(90deg); }
.r180{ transform: rotate(180deg); }
.r270{ transform: rotate(270deg); }

.gap0{ row-gap: $xs; column-gap: $xs; }
.gap05{ row-gap: 6px; column-gap: 6px; }
.gap1{ row-gap: $s; column-gap: $s; }
.gap15{ row-gap: $ms; column-gap: $ms; }
.gap2{ row-gap: $m; column-gap: $m; }
.gap3{ row-gap: $l; column-gap: $l; }
.gap4{ row-gap: $xl; column-gap: $xl; }
.gap5{ row-gap: $xxl; column-gap: $xxl; }
.op75{ opacity: 0.75; }
.op50{ opacity: 0.5; }
.op25{ opacity: 0.25; }
.grayscale{
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
.text-cut{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-cut-normal{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

// absolute positioning
.top0{top: 0;} .top1{top: $s;} .top2{top: $m;} .top3{top: $l;}
.right0{right: 0;} .right1{right: $s;} .right2{right: $m;} .right3{right: $l;}
.bottom0{bottom: 0;} .bottom1{bottom: $s;} .bottom2{bottom: $m;} .bottom3{bottom: $l;}
.left0{left: 0;} .left1{left: $s;} .left2{left: $m;} .left3{left: $l;}

// mobile
.mobile-only{
  @include responsive.tablet {
    display: none;
  }
}

.tablet-only{
  @include responsive.mobile {
    display: none;
  }
}

// Containers
.area-container{
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: $pageContainerPadding;
  border-radius: $b-rad;
  margin-top: $l;
  background: var(--background-color-1);

  @include responsive.mobile{
    padding: $pageContainerPaddingMobile;
  }
}

.groups-container{
  --fitFill: auto-fit;
  display: grid;
  grid-template-columns: repeat(var(--fitFill), minmax(210px, 1fr));
  justify-content: stretch;
  column-gap: $s;
  row-gap: $s;

  &.auto-fill{
    --fitFill: auto-fill;
  }
  
  @include responsive.mobile{
    grid-template-columns: repeat(var(--fitFill), minmax(150px, 1fr));
  }

  &.medium{
    grid-template-columns: repeat(var(--fitFill), minmax(320px, 1fr));
  }
  
  &.large{
    grid-template-columns: repeat(var(--fitFill), minmax(400px, 1fr));
  }
}

.cross-cursor{
  cursor: url("data:image/svg+xml,%3Csvg height='16' width='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ctitle%3Ed-remove%3C/title%3E%3Cg%3E%3Cpolygon points='12.2,0.9 8,5.2 3.8,0.9 0.9,3.8 5.2,8 0.9,12.2 3.8,15.1 8,10.8 12.2,15.1 15.1,12.2 10.8,8 15.1,3.8 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E") 16 16, auto;
}
.svg-icon svg {
  height: 100%;
  width: 100%;
}
.subtleBorderColor {
  border-color: rgba(155, 155, 155, 0.25);
}

// Drag and Drop
#custom-drag-layer{
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  #custom-drag-layer-item{
    background: var(--background-color-2);
    position: relative;
    border-radius: $b-rad;
    box-shadow: var(--pop-over-shadow);
  }
}

.drag-state-icon{
  position: absolute;
  width: max-content;
  left: calc(100% + #{$s});
  top: 0;
  padding: $xs $s;
  background: var(--background-color-3-inversed);
  border-radius: $b-rad;

  span{
    color: var(--text-color-inversed);
    font-size: 14px;
    line-height: 14px;
  }
}
.member-name-drag-container {
  height: $group-member-height;

  .target-overlay {
    position: static;
  }
}


.single-target{
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  border-radius: $b-rad;

  &.can-drop{
    cursor: pointer;
  }

  &.showing-icon.showing-bench{
    max-width: calc(100% - 88px);
    
    @include responsive.mobile{
      max-width: calc(100% - 44px);
    }
  }

  &.showing-bench{
    max-width: calc(100% - 44px);
  }

  &.showing-icon{
    max-width: calc(100% - 44px);

    @include responsive.mobile{
      max-width: calc(100% - 0px);
    }
  }
}

.target-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: $b-rad;
  pointer-events: none;
  z-index: 1;
  border: $b-width solid var(--background-color);
  
  &.is-active{
    border-color: colors.$success !important;
    z-index: 3;
  }
  
  &.can-drop{
    z-index: 3;
    border-color: colors.$success !important;
  }

  &.member-inside-role{
    border-radius: 0 0 $b-rad $b-rad;
  }
}

.single-target:hover .target-overlay.can-drop{
  border-color: colors.$success !important;
  z-index: 3;
}



// Form related
.form-container{
  width: clamp(250px, 100%, 450px);
  margin-bottom: $l;
}

.default-input{
  -webkit-appearance:none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--background-color) !important;
  caret-color: var(--text-color) !important;
  color: var(--text-color) !important;
  outline: none;
  border: $b-width solid var(--subtle, #d9d9d9) !important;
  border-radius: $b-rad !important;
  font-size: 14px;
  
  &input{
    box-sizing: border-box;
  }

  &:focus, &:hover{
    border-color: var(--white-on-dark) !important;
  }
}


.cell.radio .radio label:hover, .cell.checkbox:not(.image) label:hover{
  background: var(--background-color-2) !important;
}

input[type=submit] {
  background-color: var(--primary) !important;
  background: var(--primary) !important;
  color: var(--text-light) !important;
  border: none !important;
  
  &:hover{
    background-color: var(--primary-hover) !important;
    background: var(--primary-hover) !important;
    color: var(--text-light) !important;
  }
}

input[type=checkbox] {
  border-width: $b-width;
  background-color: transparent;
  
  &:not([checked]){
    background-color: var(--background-color);
    border-color: var(--subtle);
  }
  &:checked{
    background-color: var(--primary);
    border: none;
    &:focus{
      background-color: var(--primary);
    }
  }
}

input[disabled]{
  background-color: var(--subtle) !important;
}

.checkbox-label{
  border-radius: $b-rad;
  *{
    cursor: pointer;
  }

  &:hover{
    background-color: rgba(128, 128, 128, 0.085);
    // background: var(--background-color-2);
  }
}

// makes sure to buble onClick up to 
// button parent and dont select children
button.btn *{
  pointer-events: none;
}
.react-datepicker-wrapper{
  input{
    color: var(--text-color) !important;
  }
}
.cell.text-field input{
  border: 1px var(--text-color-weak, blue) !important;
}
.cell.radio .radio input:checked + label .checker{
  box-shadow: inset 0 0 0 6px var(--primary) !important;
}
.cell.radio .radio .checker{
  box-shadow: inset 0 0 0 2px var(--primary) !important;
}

// Datepicker overrides
.react-datepicker-popper{
  z-index: 2;
}
.react-datepicker, .react-datepicker__time-container{
  background: var(--background-color) !important;
  border: 1px solid var(--text-color-weak) !important;
  border-radius: $b-rad;

  *{
    color: var(--text-color) !important;
  }
}
.react-datepicker__today-button{
  border-radius: 0 0 $b-rad $b-rad !important;
}
.react-datepicker__header{
  border-radius: $b-rad $b-rad 0 0 !important;
}

.react-datepicker__today-button,
.react-datepicker__month,
.react-datepicker__header--has-time-select,
.react-datepicker__header,
.react-datepicker__time {
  background: var(--background-color) !important;
  color: var(--text-color);
}
.react-datepicker__month{
  margin: 0 !important;
  padding: $s !important;
}
.react-datepicker__time, .react-datepicker__time-list-item{
  background: none !important;
}

.react-datepicker__time-list-item{

  &:hover, &.react-datepicker__time-list-item--selected{
    background: var(--primary) !important;
    color: var(--text-light) !important;
    &.react-datepicker__time-list-item--disabled{
      background: var(--inactive) !important;
    }
  }
}

.react-datepicker__day{
  background: var(--background-color) !important;
  
  &:hover, &.react-datepicker__day--selected{
    background: var(--primary) !important;
    color: var(--text-light) !important;
    &.react-datepicker__day--disabled{
      background: var(--inactive) !important;
    }
  }
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle{
  border-top-color: var(--background-color) !important;
}
.new-raid-form, .new-automation-form{
  
  .react-datepicker__input-container input{ 
    font-size: 14px;
    background: none;
  }

  .react-datepicker__input-container input, .css-1s2u09g-control, .css-1pahdxg-control{
    height: 51px;
  }
}

.btn.micro, .btn.micro-sq, .btn.mini, .btn.mini-sq{
  font-weight: 400 !important;
}

.please-login-container{
  padding: $xxl;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color-1);
  border-radius: $b-rad;
  flex-flow: column nowrap;
}

// hover anchor hide
.hover-anchor{
  &:not(:hover){
    .hover-target{
      display: none;
    }
  }
  &:hover{
    .hover-target-inverse{
      display: none;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .overflowY-scroll{
    overflow-y: hidden;
    &:hover{
      overflow-y: auto;
    }
  }
  .overflowX-scroll{
    overflow-x: hidden;
    &:hover{
      overflow-x: auto;
    }
  }
}

@media (hover: none) and (pointer: coarse) {
  .overflowY-scroll{
    overflow-y: auto;
  }
  .overflowX-scroll{
    overflow-x: auto;
  }
}


input{
  box-shadow: none !important;
}

.cell .react-datepicker__input-container input{
  border: $b-width solid var(--subtle) !important;

  &:hover, &:focus{
    border: $b-width solid var(--white-on-dark) !important;
  }
}

.sentry-error-embed .form-submit .btn::before{
  background: none;
}

table {
  border-collapse: collapse;
}

[data-state]{
  cursor: pointer;
}

// popover arrow
.popoverTwo{
  background: var(--pop-over-bg);
  box-shadow: var(--pop-over-shadow);
  z-index: 10;
  border-radius: $b-rad;
  padding: $s;
}

.tooltip-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  overflow: hidden;
  
  &::after {
    border-radius: $b-rad;
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--pop-over-bg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: -5px;
    left: 50%;
  }
}

[data-side="top"]{
  .tooltip-arrow{
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

[data-side="bottom"]{
  .tooltip-arrow{
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }
}


.disable-popper [data-radix-popper-content-wrapper]{
  position: relative !important;
  transform: none !important;
}

[data-radix-popper-content-wrapper]{
  z-index: 150 !important;
}


// groupMember
.member-icon{
  // pointer-events: none;
  width: $group-member-height;
  height: $group-member-height;
  // margin-right: $s;
  border-radius: $b-rad;
}

.accessTag{
  padding: 1px 3px;
  border-radius: $b-rad;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid var(--secondary);
  background-color: var(--background-color-1);
  
  span{
    color: var(--secondary);
    font-weight: 600;
    font-size: 12px;
  }
}

.use-inherit-color{
  color: var(--inherit-color);
}

.use-class-container{
  @include mixins.classContainer();
}

.new-tag-position{
  position: absolute !important;
  left: 100%;
  top: -5px;
  
  @include responsive.mobile {
    left: 80%;
    top: -20px;
  }
}


// modal
.modal-bottom{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  border-top: 1px solid var(--background-color-3, lightgrey);

  padding: $ms;
  background-color: var(--background-color-1);
  border-radius: 0 0 $b-rad $b-rad;
}

// tanstack table column resize
.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2.5px;
  background: rgba(0, 0, 0, 0.3);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

table{

  thead{
    span{
      font-size: 12px;
    }
  }

  tbody{
    &:before {
      content: "@";
      display: block;
      line-height: 8px;
      text-indent: -99999px;
      color: transparent;
    }

    td{
      height: 40px; // works like min-height on <td cells
    }
    
    tr.hover-table-row-bg:hover {
      background: rgba(var(--primary-rgb), 0.04);
    }
    tr.hover-table-row-bg:hover td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    tr.hover-table-row-bg:hover td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}


.hover-shows-border{
  &:not(:hover){
    &::before{
      border: none !important;
    }
    border: none !important;
  }
}