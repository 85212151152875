@use '../base/sizing' as *;
@use '../base/colors';


// fix react-select input width in safari
.css-nwjfc{
  display: flex !important;
  
  input {
    flex: 1;
    border: none !important;
  }
}

.fill-target-container{
  width: 300px;
}
.auto-complete-container{
  font-size: 14px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  
  h5{
    margin: auto;
  }
}

.custom-input{
  color: inherit;
  opacity: 1;
  width: 100%;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: auto;
  grid-column-end: auto;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-variant-caps: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  line-height: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
  background-position: 0px center;
  user-select: all!important;
}

.domRectRef{
  width: 100%;
}

.react-select-option-core{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  box-sizing: border-box;
}
.auto-complete-container input{
  color: var(--text-color) !important;
  outline: none !important;
  border: none;
  box-shadow: none !important;
}