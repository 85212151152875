@use '../base/colors';
@use '../base/sizing' as *;

.drag-source{
  position: relative;
  display: flex;
  flex: 1;
  max-width: 100%;
  height: 100%;
  
  &.can-drag{
    cursor: move;
  }
  
  &:hover > .drag-overlay{
    opacity: 1;
  }

  .drag-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: $b-rad;
    pointer-events: none;
    border: $b-width solid var(--text-color-weak, colors.$medium-light);
    opacity: 0;

    &.in-assignments{
      opacity: 1;
    }
  }
}