@use '../../../global/styles/base/sizing' as *;

.editorContainer{
  border-radius: $b-rad;
  padding: 34px;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.editor{
  // background: cyan;
  height: 100%;
  flex: 1;
}

.saveBtn{
  width: 100%;
  margin-top: $m;
}

.contentContainer{
  padding: $m;
  border-radius: $b-rad !important;
  width: 100%;
  min-height: 50px;

  h1, h2, h3, h4, h5, h6{
    margin-bottom: 0.4em;
  }

  p{
    margin-bottom: 0.5em;
  }

  img{
    max-width: 100%;
  }

  .iframeContainer{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  a{
    text-decoration: underline;
    
    &:hover{
      color: #0077CC
    }
  }
}